/* styles.css */
.project-container {
    background-color: #01443b;
    color: #fff;
    padding: 20px;
  }
  .about-container{
    background-color: #01443b;
    color: #fff;
    /* padding-top: 50px; */
    margin-top: -15px;
  }
    
  
  .sticky {
    position: sticky;
    top: 0; 
    padding: 10px 0; 
    z-index: 9999; 
    pointer-events: none;
  }

  .project-header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* margin-bottom: 20px; */
    display: flex;
    flex-wrap: wrap;
  }
  
  .project-header img {
      flex: 1 1 300px;
      max-width: 625px;
      padding: 10px;
    }
    @media(max-width:786px){
      .project-header img {
        flex: 1 1 300px;
        max-width: 100%;
        padding: 10px;
      }

    }
    @media(min-width:1500px){
      .project-header img {
        flex: 1 1 300px;
        max-width: 1000px;
        padding: 10px;
      }
    }
  
  .project-details {
    flex: 1 1 300px;
    max-width: 500px;
    padding: 10px;
  }
  @media(min-width:1500px){
    .project-details {
      flex: 1 1 300px;
      max-width: 900px;
      padding: 10px;
    }

  }
  
  .project-amenities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  @media (max-width: 768px) {
    .project-header {
      flex-direction: column;
      text-align: center;
     /* margin-bottom: 5%; */
     display: flex;
     flex-wrap: wrap;
    }
  
  } 
  .image-container{
    align-items: center;
  }


  .project-card {
    
    background-color: #01443b;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 2px solid #ECBF5D;
  }
  
  @media (max-width: 1200px) {
    .project-card {
      flex: 1 0 22%; /* 4 items per row */
      max-width: 22%;
    }
  }
  
  @media (max-width: 992px) {
    .project-card{
      flex: 1 0 30%; /* 3 items per row */
      max-width: 30%;
    }
  }
  
  @media (max-width: 768px) {
    .project-card {
      flex: 1 0 100%; /* 2 items per row */
      max-width: 30%;
    }
  }
  
  @media (max-width: 576px) {
    .project-card {
      flex: 1 0 100%; /* Full width for 1 item per row */
      max-width: 42%;
    }
  }
  
  .icon-page{
    background-image:url("../assets/images/amen.png") ;
    background-size: cover; /* Adjust the size to cover the entire area */
    background-position: center; 
    background-color: #01443b;
    
}
.project-amenities {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  
  .project-amenities h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  /* .row { */
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: center; */
  /* } */
  .row-1{
    display: flex;
    flex-wrap: wrap;
  }
  
  .amenity {
    background: #01443b;
    color: #fff;
    flex: 1 0 18%; /* Approximately 1/5th of the container for 5 items per row */
    max-width: 16%; /* Approximately 1/5th of the container for 5 items per row */
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.2s;
    border-radius: 10px;
    opacity: 0.7;
    border:1px solid #ECBF5D;
  }
  
  .amenity:hover {
    transform: scale(1.1);
  }
  
  .amenity img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .amenity p {
    font-size: 1.2em;
    color: #fff;
  }
  
  @media (max-width: 1200px) {
    .amenity {
      flex: 1 0 22%; /* 4 items per row */
      max-width: 22%;
    }
  }
  
  @media (max-width: 992px) {
    .amenity {
      flex: 1 0 30%; /* 3 items per row */
      max-width: 30%;
    }
  }
  
  @media (max-width: 768px) {
    .amenity {
      flex: 1 0 100%; /* 2 items per row */
      max-width: 30%;
    }
  }
  
  @media (max-width: 576px) {
    .amenity {
      flex: 1 0 100%; /* Full width for 1 item per row */
      max-width: 42%;
    }
  }
  @media (max-width: 768px) {
  .project-container{
    height: 950px;
  }}
  @media(max-width:786px){
    .pro-info{
      margin-top: 30%;
    }
  }
  @media (max-width: 376px) {
    .project-container{
      height: 145vh;
    }}
    @media (max-width: 330px) {
      .project-container{
        height: 160vh;
      }}
.award-container{
  background-color: #01443b;
  color: #fff;
  padding: 20px;

}
.boader{
  margin-top: -15px;
}

@media (max-width: 330px) {
  h2{
  font-size: larger;
  }}

