/* {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background-color: #004E44; 
}

.video-containers {
  position: relative;
  width: 100%;
  max-width: 800px; 
  overflow: hidden;
}

.video-fluids {
  width: 100%;
  height: auto; 
  display: block;
}

.play-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pause-button {
  width: 50px;
  height: 50px;
  opacity: 0.7; 
}

.logo-containers {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 5px 10px;
  border-radius: 5px;
} */

.containers.video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #01443b; /* Optional: Add a background color */
}

.video-containers {
  position: relative;
  width: 97%;
  max-width: 100%; /* Full width */
  overflow: hidden;
}

.video-fluids {
  width: 100%; /* Ensures the video takes the full width of the container */
  height: auto; /* Maintains the aspect ratio */
  display: block;
  border-radius: 25px;
margin-bottom: 25px;
border: 1px solid #000;
}

.play-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pause-button {
  width: 50px; /* Adjust size */
  height: 50px;
  opacity: 0.7; /* Slight transparency */
}

.logo-containers {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px 10px;
  border-radius: 5px;
}
@media(max-width:580px){
  .containers.video {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #01443b; /* Optional: Add a background color */
    padding-top: 50px;
  }

}

 