.review{
    background-color: #01443b;

}
.card-rating {
    display: flex;
}
.text-muted{
    display: none;
}
h2{
    color: #fff;
} 
.cardlength{
    height: 85%;
    overflow-y: hidden;
    background-color:#01443b;
    color: #fff;
    padding-bottom: 20px;
   
    border: 1px solid #ECBF5D;
}
.review button{
    background-color: #004E44;
    color: #ECBF5D;
    border:1px solid #ECBF5D;
    border-radius: 40px;
    padding: 5px 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.review button:hover{
    background-color: #ECBF5D;
    color: #fff;
    border:1px solid #ECBF5D;
    border-radius: 40px;
    padding: 5px 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

@media (max-width: 768px) {
    .card {
        margin-bottom: 1rem;
    }
}
