:root
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
li
{
  /* font-family: Averia Serif Libre;
  font-style: italic;  */
  color: #FFF;

  
}
h2,h3,h4,h5,h6 {
  font-weight: 700;
}
:root{
  overflow-x:hidden;
 

}
p,li{
  font-size: 20px;
}
@media (max-width:768px){
  p,li{
    font-size: 16px;
  }
}
:root{
  scroll-behavior: smooth;
}
@media (min-width:1800px){
  p,li{
    font-size: 30px;
  }
}
@media (min-width:1800px){
  h1,h2,h3,h4.h5,h6{
    font-size: 45px;
  }
}
/* .container {
  max-width: 100%;
  width: auto;
  overflow-x: hidden; /* This will prevent horizontal scrolling */
  /* padding: 0 15px; /* Optional padding */
  /* box-sizing: border-box; Ensure padding and border are included in the element's total width and height */
/* }  */