/* Add this CSS to your project */
.stat-box {
   
    border-radius: 5px;
    color: 
    #fff;
    
  }
  .textcol{
    color: #F9B20E ;
  
  }
  
 
@media (max-width: 576px) {
    .stat-box {
      font-size: 0.9rem;
    }
  
    .textcol {
      font-size: 1.4rem;
    }
    .textrow{
        font-size: 0.9rem;
    }
  
    .text-warning {
      font-size: 1.7rem;
    }
  }
  
  @media (min-width: 576px) and (max-width: 768px) {
    .stat-box {
      font-size: 1.2rem;
    }
  
    .textcol {
      font-size: 1.7rem;
    }
    .textrow{
        font-size: 1rem;
    }
    .text-warning {
      font-size: 1.7rem;
    }
  }
  
  
  @media (min-width: 768px) and (max-width: 992px) {
    .stat-box {
      font-size: 1.5rem;
    }
  
    .textcol {
      font-size: 1.9rem;
    }
    .textrow{
        font-size: 1.2rem;
    }
  
    .text-warning {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 992px) {
    .stat-box {
      font-size: 1.8rem;
    }
  
    .textcol {
      font-size: 2rem;
    }
    .textrow{
        font-size: 1.6rem;
    }
  
    .text-warning {
      font-size: 2.5rem;
    }
  }