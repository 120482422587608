#root{
  overflow-x: clip;
}
.home-page {
  width: 100%;
  height: 100vh;
  position: relative;
  /* overflow: hidden; */
  /* background-size: cover; */
}

#myVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
}

.icon-bar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 4px;
  border-radius: 50px;
  background-color: #04362F;
  z-index: 1;
}

.icon {
  font-size: 1em;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.icon:hover {
  background-color: #3a6938;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.overlay h1 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #ECBF5D;
  text-align: start;
}

.overlay p {
  font-size: 1.5em;
  margin-bottom: 1em;
  text-align: start;
  font-weight: 400;
}

.contact-button {
  padding: 0.5em 1em;
  background-color: #004E44;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
  align-items: start;
  display: flex;
}

.contact-button:hover {
  background-color: #004E44;
}

.contact-button.active {
  background-color: #004E44;
}

@media (min-width: 768px) {
  .overlay h1 {
    font-size: 3em;
  }
  .overlay p {
    font-size: 2em;
  }
  .icon {
    font-size: 1em;
  }
  .contact-button {
    font-size: 1.2em;
  }
}

@media (min-width: 1024px) {
  .overlay h1 {
    font-size: 4em;
  }
  .overlay p {
    font-size: 2.5em;
  }
  .icon {
    font-size: 2.5em;
  }
  .contact-button {
    font-size: 1.5em;
  }
}
.gradiant {
  width: 100%;

  transform: scaleX(-1); /* Flips the image horizontally */
}
.overlaying{
  margin-top: -6%;
}
.icon-insta{
  width:28px
}

  
  