.project-details-container {
 
    align-items: center;
    flex-wrap: wrap;
  }
  
  .project-details-container img {
    /* width: 100%; */
    margin-bottom: 1rem;
  }
  
  .project-details-list {
   
    padding: 0;
  }
  
  .project-details-list li {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    text-align: end;
  }
  
  
  @media (min-width: 768px) {
    .project-details-container {
      flex-wrap: nowrap;
    }
  
    .project-details-container img {
      margin-bottom: 0;
    }
  }
  .img-container{
    max-width: 400px;
  
  }
  .project-details-list li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .arya{
    color:#ECBF5D ;
    margin-top: 10px;
  }
  .project-detail {
    flex: 1 1 400px;
    max-width: 622px;
    padding: 10px;
}