
  .ongoing-projects {
    background-color: #01443b;
    text-align: center;
    padding: 20px;
  }
  
  .project-header {
    color: #fff;
    padding: 20px;
  }
  
  .project-header .getin {
    margin-bottom: 20px;
  }
  
  .project-details p {
    margin: 20px 0;
  }
  @media(max-width:786px){
    .ongoing-projects {
      background-color: #01443b;
      text-align: center;
      padding: 20px;
      height:1000px;
    }
  }
  
  button {
    background-color: #004E44;
    color: #ECBF5D;
    border: 1px solid #ECBF5D;
    border-radius: 10px;
    padding: 5px 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
  button:hover {
    /* background-color: #ECBF5D; */
    color: #fff;
    border-radius: 255px;
  }
  
  