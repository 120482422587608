.nav-indicator {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
}

.nav-indicator div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: transparent;
    cursor: pointer;
    position: relative;
}

.nav-indicator .active {
    background-color: orange;
}

@media (max-width: 786px) {
    .nav-indicator {
        display: none;
    }
}

.nav-indicator div:hover::after {
    content: attr(title); /* Use the title attribute */
    position: absolute;
    top: 50%; /* Center the tooltip vertically */
    right: 40px; /* Position the tooltip to the left of the circle */
    transform: translateY(-50%);
    background-color: #01443B;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.nav-indicator div:hover::before {
    content: '';
    position: absolute;
    top: 50%; /* Center the arrow vertically */
    right: 30px; /* Position the arrow just before the tooltip */
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #fff transparent transparent; /* Arrow pointing left */
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.nav-indicator div::after,
.nav-indicator div::before {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}


