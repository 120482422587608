.gallery-container {
    padding: 20px 0;
    background-color: #01443b;
  }
  
  .main-image {
    text-align: center;
  }
  
  .main-image img {
    width: 100%;
    max-height: 500px; /* Adjust as needed */
    /* object-fit: cover; */
  }
  
  /* .row.mt-4 {
    margin-top: 0 !important;
  } */
  
  .row.mt-4 > [class*='col-md-2.4'] {
    flex: 0 0 auto;
    width: 20%;
    padding: 0 5px; /* Adjust as needed */
  }
  
  .img-fluid {
    width: 100%;
    height: auto;
    display: block;
  }
  .head{
    color: #fff;
  }
  
  