/* styles.css */

.service-nav {
    display: flex;
    margin-top: 25px;

  }
  
  .service-nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    border: 1px solid #D2AB56;
    border-radius: 25px;
    margin-bottom: 50px;
   }
  
  /* .service-nav-item {
    margin: 0 10px;
  } */
  .service-nav-link {
    text-decoration: none;
    text-align: center;
  }
  
  .container-fluid {
    /* display: flex; */
    justify-content: center;
    align-items: center;
 
  }
  .master-plan{
    background-image: url('../assets/images/master.png');
    background-size: cover; 
    padding-top: 20px;
  }
  .flex-row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-img {
   width: 70%;
    height: auto;
  }
  .hero-imgs{
    width: 61.65%;
    height: auto;
  }
  @media (max-width: 768px) {
    .hero-img {
      width: 100%;
    }
 
  .hero-imgs{
    width: 88%;
    height: auto;
  }
}
  @media (max-width: 576px) {
    .hero-img {
      width: 100%;
    }
    .hero-imgs{
      width: 88%;
      height: auto;
    }
  }
  .service-nav-list {
    list-style: none;
    padding: 0;
    display: flex;

    justify-content: center;
    gap: 20px;
  }
  
  .service-nav-item {
    display: inline-block;
  }
  
  .service-nav-link {
    border:none;
    background: transparent;
    color: white;
    font-size: 1.2em;
    padding: 5px 20px;
    text-decoration: none;
    transition: background 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  
  .service-nav-item.active .service-nav-link {
    background-color: #2f4f4f; /* Example color for the active button */
    border-radius: 25px;
    border:1px solid 
    #A59856;
    color: white;
  }
  @media (max-width: 767px) {
    .service-nav-list {
      flex-direction: row;
      gap: 10px;
    }
  
    .service-nav-link {
      font-size: 0.8em;
      padding: 3px 17px;
    }
  }