.contact-container {
    font-family: Arial, sans-serif;
    text-align: center;
    /* background-image: url('../assets/images/building.png'); Correct path to the image */
  background-size: cover; /* Adjust the size to cover the entire area */
  background-position: center; /* Center the background image */
  background-color:#01443b;
  /* padding-top: 5%; */
}
  
  
  .contact-form-container {
    /* background-image: url('../assets/images/building.png'); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* padding-top: 50px; */
  }
  
  .image-container {
    flex: 1 1 300px;
    max-width: 1000px;
    padding: 10px;
  }
  
  .contact-img {
    /* max-width: 550px; */
    height: auto;
    width: 100%;
  }
  
  .contact-form {
    flex: 1 1 300px;
    max-width: 400px;
    padding: 10px;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
  }
  
  .contact-form input,
  .contact-form select,
  .contact-form button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background: none;
    border: 2px solid#8B7B58;
    color: #d7d7d7; 
    font-style: nunito;
  }
  ::placeholder{
    color: #d7d7d7;
    font-style: nunito;
  }
  .contact-form select,icon{
    padding-left: 10px;
  }
  /* option{
    color:#d7d7d7 ;
    padding-left: 10px;
  } */

  .dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-btn { 
    /* color: white; */
    padding: 100px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    position: relative;
}

/* Align icon to the right */
.dropdown-icon {
    margin-left: 10px;
    font-size: 16px;
    vertical-align: middle;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1;
}

/* Show the dropdown content on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
 
   .contact-form .floating-select{
    padding: 10px;
    /* position: relative; */
  padding-right: 2.5em;
   }

   .contact-form .floating-select::after {
    content: '▼'; /* Or you can use an icon font or SVG */
    position: absolute;
    right: 0.5em; /* Adjust to align the icon */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* So the icon doesn’t interfere with dropdown clicks */
  }

  .contact-form button {
    color: black;
    background-color: #ECBF5D;
    border-radius: 5px;
  }

/* .dropdown{
  background-color: #8B7B58;

  }  */
  .contact-details h3 {
    /* padding: 10px; */
    color: #ECBF5D;
  }
  .contact-details {
    padding: 20px;
  }
  
  .contact-details div {
    margin-bottom: 20px;
    color: #fff;
  }
  
  .contact-details  {
    margin: 0 10px;
  }
  
  @media (max-width: 768px) {
    .contact-form-container {
      flex-direction: column;
    }
  
    .contact-img {
      max-width: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .contact-form-container {
      padding: 10px;
    }
  
    .contact-form {
      max-width: 100%;
    }
  
    .contact-details {
      padding: 10px;
    }
  }
  .address{
    display: flex;
    justify-content: center;
    column-gap: 10%;
  }
  @media(max-width:786px){
  .address{
    display: block;
  }}
 .getin{
   color: #fff;
   padding: 10px;
   z-index: 1 !important;
   /* border-bottom: 2px solid #ECBF5D; 
   border-width: 5px;
   border-left-width:5px ; */

  
  }
  @media(max-width:700px){
    .getin{
      color: #fff;
      padding: 10px;
      z-index: 0 !important;
    }
  }
  .downnav {
    padding: 20px; /* Adjust padding as needed */
    color: #fff;/* Optional background color */
    border-radius: 8px;
    margin: 20px; /* Gap on all four sides */
  }
  
  .downnav-list {
    list-style-type: none; /* Remove default list item dots */
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
  }
  
  .downnav-list li {
    margin: 10px 0; /* Space between list items */
    font-size: 18px; /* Adjust font size as needed */
  }
  @media (max-width:768px){
    .downnav-list{
        display: block;
        text-align: start;
    }
  }

    .social_icon {
        width: 25px;
        height: 20px;
      
    }
    a{
      text-decoration: none;
     color: #fff;
    }
    a:hover{
      color: #ECBF5D;
      text-decoration: none;
    }
    .label-over {
      position: relative;
      margin-bottom: 20px;
      display: block;
      font-size: 16px;
      color: #fff; /* White text color for the label */
    }
    
    .label-over span {
      position: absolute;
      top: 18px;
      left: 20px;
      background-color: #01443b;
      padding: 0 5px;
      font-weight: bold;
  }

    
    .error {
      color: red;
      font-size: 14px;
      margin-top: 5px;
    }
option{
  color:#ECBF5D;
  background-color: #01443b;
 
}
.navigations{
  height: 40px;
  width:45px;
}
.social{
  height: 50px;
  width: 50px;
}
    
    