.header-container {
  background-color: #032B26;
  padding: 10px 20px;
  opacity: 0.9;
  position: fixed;
  width: 100%;
  z-index: 1;
  }
  .toggle{
    width:30px;
    margin-bottom: 5px;
    position: relative; 
    z-index: 10005; 
    pointer-events: auto;
  } 
 
   .menu-btn {
    background:#01443b ;
    /* border: 2px solid #ECBF5D; */
    color: #ECBF5D; 
    z-index: 10006;
  }
  .sticky {
    position: sticky;
    top: 0; 
    padding: 10px 0; 
    z-index: 9999; 
  }
  .header-content {
    max-width: 100%;
    /* margin: 0 auto; */
   }
  
  .logo-section img {
    height: auto;
    max-width: 50%;
  }
  .last{
    margin-bottom: 10px;
  }
  @media(max-width:586px)
  {
    .logo-section img{
        max-width: 30%;
        margin-left: -18px;
        margin-top: -44px;
    
    
    
    }
    
    }   
    .header-container.scrolled {
      padding-top: 50px;
      margin-top: 5px;
      position: fixed;
    }
  
  .enquire-now-btn {
    background-color: #01443b; 
    color:#ECBF5D ;
    border: 1px solid #ECBF5D;
    padding: 5px 20px;
    margin-right: 10px;
    border-radius: 50px;
  }
  .enquire-now-btn:hover{
    color: #ECBF5D ;
    border: 1px solid #ECBF5D;

  }
  @media (max-width:586px){
    .enquire-now-btn {
      background-color: #032B26; 
      color:#ECBF5D ;
      border: 1px solid #ECBF5D;
      padding: 5px 10px;
      margin-right: 10px;
      border-radius: 50px;
      display: none;
    }

  }
  
 
  
  @media (max-width: 768px) {
    /* .header-content {
      flex-direction: column;
    } */
  
    /* .nav-section {
      margin-top: -45px;
    } */
  
    .enquire-now-btn, .menu-btn {
      width: 80%;
      margin: 0px 0;
    }
  }
 


 /* Offcanvas.css */

/* General styles for the Offcanvas component */
.offcanvas-custom {
  z-index: 1050;
 max-width: 300px;
  background-color: #032B26;
  pointer-events: auto;

}

/* Header styles */
.offcanvas-custom .offcanvas-header {
  border-bottom: 1px solid #dee2e6;
}

/* Close button styles */
.offcanvas-custom .offcanvas-header .btn-close {
  font-size: 1.25rem;
  color: #fff;
  cursor: pointer;
  border: 1px solid #ECBF5D ;
  background-color: #ECBF5D;
}
 a{
  cursor: pointer;

}

/* Body styles */
.offcanvas-custom .offcanvas-body {
  padding: 1rem;
}

/* Menu item styles */
.offcanvas-custom .offcanvas-body h6 {
  margin-bottom: 1rem;
  font-weight: 600;
  color: #fff;
}
.offcanvas-custom .offcanvas-body h6 a :hover {
  margin-bottom: 1rem;
  font-weight: 600;
  color:#ECBF5D ;
  
}
@media(max-width:786px){
  .offcanvas-custom {
    max-width: 150px;
     background-color: #01443b;
   
   }
}

.active {
  font-weight: bold;
  color: #ECBF5D;
}
